<template>
  <v-col class="mt-7 mr-2" cols="12" md="3" sm="3">
    <v-select
      :items="santralList"
      label="Kullanıcı Seç"
      v-model="selectedprefix"
      solo
      dense
      @change="selectUser"
    ></v-select>
  </v-col>
</template>
 

<script>
import request from "@/api/utils";

export default {
  name: "AppProfile",
  data() {
    return {
      selectedprefix: null,
      santralList: [],
    };
  },
  computed: {},
  created() {
    this.getUserList();
  },
  methods: {
    getUserList() {
      let params = {};
      if (
        this.$store.getters.prefixList !== undefined &&
        this.$store.getters.prefixList !== null
      ) {
        params.condiniton = {
          prefix: this.$store.getters.prefixList,
        };
      }
      const storage = window.localStorage;
      let selectedprefix = storage.getItem("selectedprefix");

      if (selectedprefix !== undefined && selectedprefix !== null) {
        this.selectedprefix = window.atob(selectedprefix);
      }
      let temp = request.Api("/panel/user/list", params, "post");
      temp.then((res) => {
       
        if (res.data.success == "true") {
              
          if (res.data.data.length === 0) {
            return;
          }
            
          this.santralList = [];
          Object.keys(res.data.data).forEach((index) => {
            this.santralList.push({
              value: res.data.data[index].prefix,
              text: res.data.data[index].company_name,
            });
          });

          if (selectedprefix === undefined || selectedprefix === null) {
            this.selectedprefix = res.data.data[0].prefix;
          }
        }
      });
    },
    selectUser() {
      const storage = window.localStorage;
      storage.setItem("selectedprefix", window.btoa(this.selectedprefix));
     // window.location.reload();
    },
  },
};
</script>

<style scoped>
#btnModifyProfile:hover {
  color: orange !important;
  background-color: white !important;
  border: 2px solid orange;
}

#btnNewUser:hover {
  color: green !important;
  background-color: white !important;
  border: 2px solid green;
}
</style>
