// eslint-disable-next-line import/no-cycle
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import request from '../../api/utils';

const cube = {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
};

export default cube;
