<template>
  <div>
    <v-btn @click.stop="drawer = !drawer">
      Saha Seç
    </v-btn>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      hide-overlay
      right
      height="100vh"
      class="mb-10"
    >
      
      <span class="text-center mb-10 mt-10 ml-10 text-h6">Saha Seç </span>

      <v-divider />

      <template class="mt-2 mb-10" v-for="item in santralList">
        <v-btn
          v-if="selectedprefix == item.value"
          class="mt-2 ml-2 light-blue lighten-3"
          depressed
          small
          @click="selectUser(item)"
        >
          {{ item.text }}
          <v-icon
            color="orange darken-4"
            right
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="mt-2 ml-2 blue lighten-1"
          depressed
          small
          @click="selectUser(item)"
        >
          {{ item.text }}
          <v-icon
            color="orange darken-4"
            right
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </template>
       <v-divider />
    </v-navigation-drawer>
  </div>
</template>

<script>
import request from '@/api/utils';

export default {
  name: 'AppProfile',
  data() {
    return {
      selectedprefix: null,
      santralList: [],
      drawer: null,
    };
  },
  computed: {},
  created() {
    this.getUserList();
  },
  methods: {
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    },
    getUserList() {
      const params = {};
      if (
        this.$store.getters.prefixList !== undefined
        && this.$store.getters.prefixList !== null
      ) {
        params.conditions = {
          prefix: this.$store.getters.prefixList,
        };
      }
      const storage = window.localStorage;
      const selectedprefix = storage.getItem('selectedprefix');

      if (selectedprefix !== undefined && selectedprefix !== null) {
        this.selectedprefix = window.atob(selectedprefix);
      }
      const temp = request.Api('/panel/user/list', params, 'post');
      temp.then((res) => {
        if (res.data.success === 'true') {
          if (res.data.data.length === 0) {
            return;
          }
          this.santralList = [];
          
          Object.keys(res.data.data).forEach((index) => {
            this.santralList.push({
              value: res.data.data[index].prefix,
              text: res.data.data[index].company_name,
            });
          });

          if (selectedprefix === undefined || selectedprefix === null) {
            this.selectedprefix = res.data.data[0].prefix;
          }
        }
      });
    },
    selectUser(item) {
      const storage = window.localStorage;
      storage.setItem('selectedprefix', window.btoa(item.value));
      window.location.reload();
    },
  },
};
</script>

<style scoped>
#btnModifyProfile:hover {
  color: orange !important;
  background-color: white !important;
  border: 2px solid orange;
}

#btnNewUser:hover {
  color: green !important;
  background-color: white !important;
  border: 2px solid green;
}
</style>
