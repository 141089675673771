import Axios from 'axios';

// const urlPrefix = 'http://138.68.70.91';
// const baseURL = 'http://138.68.70.91';

/* const urlPrefix = 'http://localhost:8888';
const baseURL = 'http://localhost:8888'; */

let baseURL = 'https://myapi.datasmarty.net';
 //let baseURL = 'https://api.datasmarty.net';
 //let baseURL = 'https://demo.datasmarty.net';

const url = window.location.href;
if (url.indexOf('localhost') > -1) {
//   baseURL = 'http://localhost:8888';
}

const storage = window.localStorage;

const service = Axios.create({
  headers: {
    'Content-type': 'application/json;charset=utf-8',
  },
});

service.interceptors.request.use((request) => {
  const token = storage.getItem('vuex_user');

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  if (baseURL) {
    request.baseURL = baseURL;
  }
  return request;
});

service.interceptors.response.use((response) => response, (error) => {
  console.log('http error', error);
  return Promise.reject(error.status ? error : error.response);
});

function Api(baseurl, params, method) {
  params = params || {};

  return new Promise((resolve, reject) => {
    let ret = null;

    const selectedprefix = storage.getItem('selectedprefix');

    if (selectedprefix !== null) {
      if (typeof params === 'object') {
        // eslint-disable-next-line no-param-reassign
        params.client = selectedprefix;
      }
    }

    const upperMethod = method.toUpperCase();
    if (upperMethod === 'POST') {
      ret = service.post(baseurl, params);
    } else if (upperMethod === 'PUT') {
      ret = service.put(baseurl, params);
    } else if (upperMethod === 'DELETE') {
      ret = service.delete(baseurl, params);
    } else {
      ret = service.get(baseurl, params);
    }

    resolve(ret);
    reject(ret);
  });
}

export default {
  Api,
};
