import Layout from '@/views/layout/TheLayout.vue';

const stringBox = {
  path: '/stringbox',
  component: Layout,
  redirect: 'noredirect',
  name: 'Stringbox',
  meta: {
    title: 'route.Stringbox',
    icon: 'mdi-cube-outline',
    roles: ['admin'],

  },

  children: [
  /*   {
    path: 'panel',
    component: () => import('@/views/components/StringBox/StringHeatmapLimit.vue'),
    name: 'Panel Verimlilik',
    meta: {
      title: 'Panel Verimlilik',
      roles: ['admin', 'user', 'manager'],
    },
  }, */

  {
    path: 'analiz',
    component: () => import('@/views/components/StringBox/D3js.vue'),
    name: 'String Analiz',
    meta: {
      title: 'String Analiz',
      roles: ['admin', 'user', 'manager'],
    },
  },
  {
    path: 'comparison',
    component: () => import('@/views/components/StringBox/StringD3js.vue'),
    name: 'String Karşılaştırma',
    meta: {
      title: 'Karşılaştırma',
      roles: ['admin', 'user', 'manager'],
    },
  },

  {
    path: 'heatmap',
    component: () => import('@/views/components/StringBox/Heatmap.vue'),
    name: 'heatmap',
    meta: {
      title: 'Heatmap',
      roles: ['admin', 'user', 'manager'],
    },
  },
  /*    {
         path: 'status',
         component: () => import('@/views/components/StringBox/Status.vue'),
         name: 'status',
         meta: {
           title: 'status',

         },
       }, */
  {
    path: 'heatmapcomp',
    component: () => import('@/views/components/StringBox/HeatmapComparison.vue'),
    name: 'heatmapcomp',
    meta: {
      title: 'Stringler',
      roles: ['admin', 'user', 'manager'],
    },
  },
  {
    path: 'string',
    component: () => import('@/views/components/StringBox/Minute.vue'),
    name: 'String',
    meta: {
      title: 'route.String',
      roles: ['admin', 'user', 'manager'],
    },
  },
  {
    path: 'analysis',
    component: () => import('@/views/components/StringBox/TimeOfStringBox.vue'),
    name: 'Analysis',
    meta: {
      title: 'route.Analysis',
      roles: ['admin', 'user', 'manager'],
    },
  },

  ],
};

export default stringBox;
