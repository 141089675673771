import Layout from '@/views/layout/TheLayout.vue';

const stringBox = {
  path: '/inverter',
  component: Layout,
  redirect: 'noredirect',
  name: 'Inverter',
  meta: {
    title: 'route.İnverter',
    icon: 'mdi mdi-stove',
    roles: ['admin', 'user', 'manager'],

  },

  children: [
    {
      path: 'heatmap',
      component: () => import('@/views/components/Inverter/Heatmap.vue'),
      name: 'heatmap',
      meta: {
        title: 'Karşılaştır',
        roles: ['admin', 'manager', 'user'],
      },
    },
    {
      path: 'status',
      component: () => import('@/views/components/Inverter/Status.vue'),
      name: 'status',
      meta: {
        title: 'Uyarı',
        roles: ['admin', 'manager', 'user'],
      },
    },
    {
      path: 'statuss',
      component: () => import('@/views/components/Inverter/InverterStatus.vue'),
      name: 'statuss',
      meta: {
        title: 'Durum',
        roles: ['admin', 'manager', 'user'],
      },
    },
    {
      path: 'live',
      component: () => import('@/views/components/Inverter/InverterInstant.vue'),
      name: 'live',
      meta: {
        title: 'Anlık',
        roles: ['admin', 'manager', 'user'],
      },
    },
    {
      path: 'inverter',
      component: () => import('@/views/components/Inverter/Index.vue'),
      name: 'İnverter',
      meta: {
        title: 'İnverter',
        roles: ['admin', 'manager', 'user'],

      },
    },
    {
      path: 'analysis',
      component: () => import('@/views/components/Inverter/TimeOfInverter.vue'),
      name: 'Analysis',
      meta: {
        title: 'Analysis',
        roles: ['admin', 'manager', 'user'],

      },
    },

  ],
};

export default stringBox;
