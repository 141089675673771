export default {
  search: 'Ara',
  dimensions: {

  },
  measures: {

  },
  table: {
    name: 'İsim',
  },

};
