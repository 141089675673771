import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VuePivottable from 'vue-pivottable';
import vuetify from './plugins/vuetify';
import i18n from './locale'; // Internationalization
import API from './api';

import 'vue-pivottable/dist/vue-pivottable.css';

import App from './App.vue';
import router from './router';
import store from './store';

import './permission';
import './registerServiceWorker';

Vue.use(VuePivottable);

Vue.config.productionTip = false;
Vue.prototype.$api = API;
Vue.use(Vuelidate);

Vue.filter('toDateTime', (value) => {
  if (value > 1) {
    const date = new Date(value * 1000);
    const year = date.getFullYear();
    const month = date.getUTCMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const min = date.getMinutes();
    return `${day}/${month + 1}/${year} ${hours}:${min}`;
  }
  return 'Bilinmiyor';
});

// eslint-disable-next-line import/prefer-default-export
export const eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
