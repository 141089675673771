export default {
  dashboard: 'Dashboard',
  cube: 'Cube',
  reports: 'Reports',
  menu: 'ReportsMenu',
  dashlist: 'DashList',
  cubes: 'Cubes',
  rawdata: 'Raw Data',
  desk: 'Help Desk',
  notification: 'Notifications',
  forecast: 'Forecast',
  livedata: 'Live Data',
  settings: 'Settings',
  permissions: 'Permission',
  profile: 'Profile',
  googlemaps: 'Google Maps',
  daily: 'Günlük',
  Weekly: 'Haftalık',
  Monthly: 'Aylık',
  Yearly: 'Yıllık',
  cubePages: {
    cube: 'Cube',
    list: 'ListView',
    cubelist: 'ListViewCube',
    pivot: 'PivotTable',
    chart: 'Chart',
  },
  rawPages: {
    order: 'Order',
    orderProduct: 'Order/Product',
    customer: 'Customer',
    product: 'Product',
    subproduct: 'SubProduct',
    category: 'Category',
    brand: 'Brand',
    supplier: 'Supplier',
    profile: 'Profile',
  },
  profilePages: {
    payNow: 'Pay Now',
    payments: 'Payments',
    users: 'Users',
    onlineUsers: 'Online Users',
    modifyProfile: 'Modify Profile',
    newUser: 'New User',
  },
  deskPages: {
    new: 'New Support Request',
    actual: 'Actual Support Request',
  },
  notificationPages: {
    saved: 'Saved Notifications',
    regular: 'Regular Notifications',
  },
  settingPages: {
    service: 'Service Settings',
    order: 'Order Status Settings',
    showcase: 'Showcase',
  },
  forecastPages: {
    stock: 'Stock Forecast',
    standard: 'Forecast',
  },
  errors: 'Errors',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500',
  },
  charts: 'Charts',
  chart: {
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
  },
};
