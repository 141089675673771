import store from '../store/index';

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
function is_Empty({
  data,
} = {}) {
  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true;
    }
    if (!data) {
      return true;
    }
    return false;
  }
  if (typeof data === 'string') {
    if (!data.trim()) {
      return true;
    }
    return false;
  }
  if (typeof data === 'undefined') {
    return true;
  }
  return false;
}

function isEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return true;
}
const reverseObj = function (object) {
  const NewObj = {};
  const keysArr = Object.keys(object);
  for (let i = keysArr.length - 1; i >= 0; i--) {
    NewObj[keysArr[i]] = object[keysArr[i]];
  }
  return NewObj;
};

function setDailyData(y, m, d) {
  m = parseInt(m);
  d = parseInt(d);

  let month;
  let
    day;
  m < 10 ? month = `0${m}` : month = `${m}`;
  d < 10 ? day = `0${d}` : day = `${d}`;

  return `${y}-${month}-${day}`;
}

function getUserStep() {
  const {
    santralList
  } = store.getters;

  let step_time = 5;
  Object.keys(santralList).forEach((k) => {
    if (santralList[k].hasOwnProperty("dataLoggerTimeInterval")) {
      step_time = santralList[k].dataLoggerTimeInterval;

    }
  });
  return step_time;
}

function getSantralStep(santral) {
  const {
    santralList
  } = store.getters;
  return santralList[santral].dataLoggerTimeInterval;
}

const monthWeek = (s) => {
  const [y, m, d] = s.split('-');
  const date = new Date(y, m - 1, d);
  date.setDate(d - ((date.getDay() + 6) % 7));
  return Math.ceil(date.getDate() / 7);
};

const objToString = Object.prototype.toString;

function isArray(value) {
  return objToString.call(value) === '[object Array]';
}

function setFixed(val) {
   if (val === undefined) {
     val = 0;
   }else if (val === null)
     val = 0;
   else {
     val = parseFloat(val);
     val = val.toFixed(1);
   }

  val = parseFloat(val);
  return val;
}

function show_float_val(val, upto = 2) {
  var val = parseFloat(val);
  return  parseFloat(val.toFixed(upto));
}

export default {
  show_float_val,
  setFixed,
  is_Empty,
  isEmpty,
  setDailyData,
  getSantralStep,
  getUserStep,
  getParameterByName,
  parseJwt,
};
