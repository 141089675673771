export default {
  newCube: {
    header: 'Yeni Küp',
    cubeName: 'Küp İsmi',
    placeholder: 'Küp ismi giriniz (Product, Brand gibi)',
    measures: 'Ölçüler',
    dimensions: 'Boyutlar',
    trendsEnabled: 'Trendlere Dahil',
  },
  batchCube: {
    header: 'Toplu Küp Oluştur',
    dimensions: 'Boyutlar',
    measures: 'Ölçüler',
    combination: '(k) Kombinasyon',
    placeholder: 'Kombinasyon giriniz (2,3,4,5.. gibi)',
    trendsEnabled: 'Trendlere Dahil',
    resetCube: '(Varsa) Mevcut küpü sıfırla',
  },
  alert: {
    cubeSelection: 'Küp Seçiniz',
    dimension: 'Boyut',
    measure: 'Ölçü',
    orderType: 'Sipariş Türü',
    rate: 'Oran',
    logical: 'Mantıksal Işlem',
    language: 'Dil',
    value: 'Değer',
    description: 'Açıklama',
    startDate: 'Başlangıç Tarihi',
    endDate: 'Bitiş Tarihi',
    step: 'Adım',
  },
  delete: {
    question: 'Seçilen küp silinecek, Devam etmek istiyor musunuz ?',
  },
  selectCubeReset: {
    question: 'Seçilen küp sıfırlanacak, Devam etmek istiyor musunuz ?',
  },
  allCubeReset: {
    question: 'Tüm küpler sıfırlanacak, Devam etmek istiyor musunuz ?',
  },
  tableDeleteItem: {
  },
};
