import Layout from '@/views/layout/TheLayout.vue';

const santral = {
  path: '/santral',
  component: Layout,
  redirect: 'noredirect',
  name: 'Santral',
  meta: {
    icon: 'mdi-view-dashboard',
    title: 'Santral',
    roles: ['admin', 'manager', 'user'],

  },

  children: [{
    path: 'analysis',
    component: () => import('@/views/components/Santrals/Index.vue'),
    name: 'Analysis',
    meta: {
      title: 'Analiz',
      icon: 'mdi-view-dashboard',
      roles: ['admin', 'manager', 'user'],
    },
  },

  {
    path: 'heatmapcomp',
    component: () => import('@/views/components/Santral/HeatmapComparison.vue'),
    name: 'heatmapcomp',
    meta: {
      title: 'Karşılaştır',
      roles: ['admin', 'manager', 'user'],
    },
  },
  {
    path: 'heatmap',
    component: () => import('@/views/components/Santral/Heatmap.vue'),
    name: 'heatmap',
    meta: {
      title: 'Heatmap',
      icon: 'mdi-chart-bar',
      roles: ['admin', 'manager', 'user'],
    },
  },

  ],
};

export default santral;
