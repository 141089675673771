import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/views/layout/TheLayout.vue';
import authRouter from './modules/auth';
import analysis from './modules/analysis';
import santral from './modules/santral';
import errorsRouter from './modules/errors';
import notificationRouter from './modules/notifications';
import stringBox from './modules/stringbox';
import inverter from './modules/inverter';
import forecast from './modules/forecast';

Vue.use(Router);

export const constantRoutes = [
   {
    path: '/map',
    component: Layout,
    children: [
      {
      path: '/map',
      alias: '/',
      component: () => import('@/views/components/SantralList/GoogleMaps.vue'),
      title: 'Harita',
      meta: {
        title: 'Harita',
        icon: 'mdi-chart-bar',
        affix: true,
      }
      }],
  },  
  /*  {
     path: '/svgmap',
     component: Layout,
     children: [{
       path: '/svgmap',
       alias: '/',
       component: () => import('@/views/components/SantralList/Harita.vue'),
       title: 'Harita',
       meta: {
         title: 'Harita',
         icon: 'mdi-chart-bar',
         affix: true,
       }
     }],
   }, */
  {
    path: '/',
    component: Layout,
    children: [{
      path: '/dashboard',
      alias: '/',
      component: () => import('@/views/components/Dashboard/Index.vue'),
      name: 'main',
      meta: {
        title: 'route.dashboard',
        icon: 'mdi-view-dashboard',

        affix: true,
        roles: ['admin', 'user', 'manager'],
      },
    }],
  },
/*   {
    path: '/dashboard',
    component: Layout,
    children: [{
      path: '/dashboard',
      alias: '/',
      component: () => import('@/views/components/Dashboard/Index.vue'),
      name: 'main',
      meta: {
        title: 'route.dashboard',
        icon: 'mdi-view-dashboard',

        affix: true,
        roles: ['admin', 'user', 'manager'],
      },
    }],
  }, */

  {
    path: '/Sensorbox',
    component: Layout,
    children: [{
      path: '/sensorbox',
      alias: '/',
      component: () => import('@/views/components/Sensorbox/Index.vue'),
      name: 'Sensorbox',
      title: 'route.Sensorbox',
      meta: {
        title: 'route.Sensorbox',
        icon: 'mdi-view-module',
        affix: true,
        roles: ['admin', 'user', 'manager'],
      },
    }],
  },

  ...authRouter,
];

export default new Router({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRoutes,
  mode: 'history',
});

export const asyncRoutes = [
  santral,
  notificationRouter,
  inverter,
  stringBox,
  analysis,
  //forecast,
  errorsRouter,
  {
    path: '*',
    redirect: '/error/404',
    hidden: true,
  },
];
