<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn
        icon
        large
        text
        v-on="on"
      >
        <v-avatar size="36px">
          <v-icon>
            mdi-account
          </v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list class="m-0 p-0">
        <v-list-item
          avatar
          class="light-blue lighten-1"
        >
          <v-list-item-avatar>
            <v-icon
              x-large
              color="grey darken-3"
            >
              mdi-account
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="d-flex">
            <div class="col-md-7">
              <v-list-item-title>{{ username }}</v-list-item-title>

              <v-list-item-subtitle class="white--text">
                {{ firstname }}
                {{ lastname }}
                {{ company_name }}
              </v-list-item-subtitle>
            </div>

            <div class="col-md-3">
              <v-btn
                rounded
                color="grey darken-3"
                class="white--text"
                @click="logout"
              >
                Log Out
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item
        v-for="(item,index) in menuitems"
        :key="index"
        :to="`/profile/${item.href}`"
        :href="item.href"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <!--  <div>
        <v-btn
          id="btnModifyProfile"
          class="amber col-md-5 ml-3 mb-3 white--text"
          height="50px"
          @click="routeToProfile('modify-profile')"
        >
          <v-icon class="text-center">mdi-pen</v-icon>
          <span>Modify Profile</span>
        </v-btn>
        <v-btn
          id="btnNewUser"
          class="success col-md-5 float-right mr-3 mb-3"
          height="50px"
          @click="routeToProfile('new-user')"
        >
          <v-icon>mdi-account-plus</v-icon>
          New User
        </v-btn>
      </div> -->
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppProfile',
  data() {
    return {
      menuitems: [
        /*  {
          icon: 'mdi-cash-multiple',
          href: 'pay-now',
          title: 'header.account.payNow',
        },
        {
          icon: 'mdi-currency-try',
          href: 'payments',
          title: 'header.account.payments',
          click: (e) => {
            console.log(e);
          },
        }, */
      /*   {
          icon: 'mdi-account',
          href: 'users',
          title: 'header.account.users',
          click: () => {
            console.log('this.toggleSettingsPanel()');
          },
        },
        {
          icon: 'mdi-account-multiple',
          href: 'online-users',
          title: 'header.account.onlineUsers',
          click: () => {
            console.log('this.toggleSettingsPanel()');
          },
        }, */
      ],
    };
  },
  computed: {
    ...mapGetters([
      'username',
      'firstname',
      'lastname',
      'company_name',

    ]),
  },
  methods: {
    logout() {
      this.$store.dispatch('LogOut');
      this.$router.push('/singin');
      window.localStorage.clear();
    },
    toggleSettingsPanel() {
      console.log('this.toggleSettingsPanel()111');
      this.$vuetify.goTo(0);
      this.$store.dispatch('SettingsPanelToggle');
    },
    routeToProfile(param) {
      this.$router.push(`/profile/${param}`);
    },
  },
};
</script>

<style scoped>
  #btnModifyProfile:hover {
    color: orange !important;
    background-color: white !important;
    border: 2px solid orange;
  }

  #btnNewUser:hover {
    color: green !important;
    background-color: white !important;
    border: 2px solid green;
  }
</style>
