export default {
  currency: {
    usd: 'USD',
    euro: 'Euro',
    try: 'Turkish Lira',
    addCurrency: 'Add Currency',
  },
  account: {
    payNow: 'Pay Now',
    payments: 'Payments',
    users: 'Users',
    onlineUsers: 'Online Users',
  },
};
