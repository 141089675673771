/* eslint-disable import/no-cycle */
import request from '../../api/utils';

const serviceSettings = {
  state: {
    serviceOrderStatusList: [],
  },
  getters: {
    serviceOrderStatusList: (state) => state.serviceOrderStatusList,
  },
  mutations: {
    SET_SERVICE_ORDER_STATUS_LIST: (state, payload) => {
      state.serviceOrderStatusList = payload;
    },
  },
  actions: {
    getServiceOrderStatusList: async ({ commit }) => {
      try {
        const req = request.get('getServiceOrderStatusList');
        req.then((res) => {
          if (res.status !== 200) return;
          if (res.data.success !== true) return;
          const result = JSON.parse(JSON.stringify(res));
          commit('SET_SERVICE_ORDER_STATUS_LIST', result.data.data);
        });
      } catch (err) {
        console.log('getServiceOrderStatusList', err);
      }
    },

    setServiceOrderStatusChange: ({ dispatch }, payload) => new Promise((resolve, reject) => {
      const req = request.post('setServiceOrderStatusChange', payload);
      req.then((res) => {
        if (res.status !== 200) return;
        if (res.data.success === true) {
          dispatch('getServiceOrderStatusList');
          resolve(true);
        }
        if (res.data.success !== true) {
          dispatch('getServiceOrderStatusList');
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false);
        }
      });
    }),
  },
};

export default serviceSettings;
