<template>
  <v-app-bar
    app
    :dense="toolbarDense"
  >
    <v-app-bar-nav-icon @click.stop="toggleNavbar">
      <v-icon>{{ toggleNavbarIcon }}</v-icon>
    </v-app-bar-nav-icon>
    <breadcrumbs />

    <v-spacer />
 
    <app-user-selected />
    <full-screen />

    <!--    <localization /> -->
    <!--  <currency />
    <help-desk />
    <notification />
    <settings /> -->
    <profile />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumbs from '../widget/AppBreadcrumbs.vue';
import FullScreen from '../widget/AppFullScreen.vue';
import Localization from '../widget/AppLocalization.vue';
// import Notification from '../widget/AppNotification.vue';
import Profile from '../widget/AppProfile.vue';
import AppUserSelect from '../widget/AppUserSelect.vue';
import AppUserSelected from '../widget/AppUserSelected.vue';

// import HelpDesk from '../widget/AppHelpDesk.vue';
// import Settings from '../widget/AppSettings.vue';
// import Currency from '../widget/AppCurrency.vue';
// import NotifyModal from "@/views/components/Notification/NotifyModal.vue";

export default {
  name: 'TheLayoutToolbar',
  components: {
    Breadcrumbs,
    FullScreen,
    Localization,
    
    AppUserSelected,
    // Notification,
    Profile,

    // HelpDesk,
    // Settings,
    // Currency,
  },
  data: () => ({
    //  alarmDialog: false,
  }),
  computed: {
    ...mapGetters(['toolbarDense', 'navbarShow']),
    toggleNavbarIcon() {
      return this.navbarShow
        ? 'mdi-format-indent-decrease'
        : 'mdi-format-indent-increase';
    },
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch('NavbarToggle');
    },
  },
};
</script>
