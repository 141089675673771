<template>
  <v-app>
    <router-view />
    <!--    <Footer />-->
  </v-app>
</template>

<script>
// import Footer from '@/views/components/Shared/Footer.vue';

export default {
  name: 'App',
  components: {
    // Footer,
  },
};
</script>
