import request from '@/api/utils';

export function getSantralList(formdata = {}, method = 'post') {
  const url = '/panel/santral/list';
  return request.Api(url, formdata, method);
}

export function getSantralStatsData(formdata = {}, method = 'post') {
  const url = '/panel/santraller/stats';
  return request.Api(url, formdata, method);
}


export function getSantralMinute(formdata = {}, method = 'post') {
  const url =   "/panel/santral/minuterpower";
  return request.Api(url, formdata, method);
}

export function getSantralHourly(formdata = {}, method = 'post') {
  const url = "/panel/santral/hourpower";
  return request.Api(url, formdata, method);
}


export function getSantralDaily(formdata = {}, method = 'post') {
  const url = "/panel/santral/dailypower";
  return request.Api(url, formdata, method);
}

export function getSantralMonthly(formdata = {}, method = 'post') {
  const url = "/panel/santral/monthlypower";
  return request.Api(url, formdata, method);
}

export function getSantralYearly(formdata = {}, method = 'post') {
  const url = "/panel/santral/yearlypower";
  return request.Api(url, formdata, method);
}




