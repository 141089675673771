import Layout from '@/views/layout/TheLayout.vue';
import ErrorPage from '@/views/components/ErrorPage.vue';

const path = ['301', '401', '403', '404', '500'];
const name = ['Page301', 'Page401', 'Page403', 'Page404', 'Page500'];
const meta = ['page301', 'page401', 'page403', 'page404', 'page500'];
const props = ['301', '401', '403', '404', '500'];

const children = [{
  path: path[0],
  component: ErrorPage,
  name: name[0],
  meta: {
    title: `route.errorPages.${meta[0]}`,
    noCache: true,
  },
  props: {
    errorCode: props[0],
  },
},
{
  path: path[1],
  component: ErrorPage,
  name: name[1],
  meta: {
    title: `route.errorPages.${meta[1]}`,
    noCache: true,
  },
  props: {
    errorCode: props[1],
  },
},
{
  path: path[2],
  component: ErrorPage,
  name: name[2],
  meta: {
    title: `route.errorPages.${meta[2]}`,
    noCache: true,
  },
  props: {
    errorCode: props[2],
  },
},
{
  path: path[3],
  component: ErrorPage,
  name: name[3],
  meta: {
    title: `route.errorPages.${meta[3]}`,
    noCache: true,
  },
  props: {
    errorCode: props[3],
  },
},
{
  path: path[4],
  component: ErrorPage,
  name: name[4],
  meta: {
    title: `route.errorPages.${meta[4]}`,
    noCache: true,
  },
  props: {
    errorCode: props[4],
  },
},
];

const errorsRouter = {
  path: '/error',
  component: Layout,
  redirect: 'noredirect',
  name: 'ErrorPages',
  hidden: true,
  meta: {
    title: 'route.errors',
    icon: 'mdi-information',

  },
  children,
};

export default errorsRouter;
