const constant = {
  state: {

  },
  getters: {

  },
};

export default constant;
