<template>
  <v-navigation-drawer
    dark
    fixed
    app
    :value="navbarShow"
    width="250"
    @input="stateNavbarShow"
  >
    <v-app-bar
      v-if="navbarLogo"
      :dense="toolbarDense"
      dark
    >
      <v-toolbar-title
        style="height: 60px;"
        class="text-center d-flex mb-5 mt-10"
      >
        <v-img
          src="https://res.cloudinary.com/datasmarty/image/upload/v1632484075/Datasmarty_Logo_icquyf.svg"
          height="40"
          width="180px"
        />
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-drawer-list
      :dense="navbarDense"
      :routes="permissionRoutes"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import TheLayoutDrawerList from './TheLayoutDrawerList.vue';

export default {
  name: 'TheLayoutDrawer',
  components: {
    TheLayoutDrawerList,
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'permissionRoutes',
      'navbarDense',
      'navbarShow',
      'navbarLogo',
      'toolbarDense',
    ]),
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch('NavbarState', { state });
    },
    getImage(param) {
      return require(`@/views/widget/images/${param}`);
    },
  },
};
</script>
<style scoped>
header.v-sheet.theme--dark.v-toolbar.v-toolbar--dense.v-app-bar {
  height: 60px;
}

</style>
