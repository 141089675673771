import Layout from '@/views/layout/TheLayout.vue';

const Analysis = {
  path: '/analysis',
  component: Layout,
  redirect: 'noredirect',
  name: 'Analysis',
  meta: {
    title: 'route.Analysis',
    icon: 'mdi-cube-outline',
    roles: ['admin', 'user', 'manager'],

  },
  children: [
    /*  {
      path: 'price',
      component: () => import('@/views/components/Price/Stats.vue'),
      name: 'Price',
      meta: {
        title: 'Prices',
        icon: 'mdi-chart-bar',
        roles: ['admin', 'user', 'manager'],
        affix: true,
      },
    }, */ 
    {
      path: 'stats',
      component: () => import('@/views/components/Stats/General.vue'),
      name: 'Stats',
      meta: {
        title: 'route.stats',
        icon: 'mdi-chart-bar',
        roles: ['admin', 'user', 'manager'],
        affix: true,
      },
    },
    {
      path: 'santral',
      component: () => import('@/views/components/Analysis/SantralAnalysis.vue'),
      name: 'Santral',
      meta: {
        title: 'route.Santral_Analysis',
        icon: 'mdi-google-analytics',
        roles: ['admin', 'user', 'manager'],
        affix: true,
      },
    },
    {
      path: 'date',
      component: () => import('@/views/components/Analysis/DateAnalysis.vue'),
      name: 'Date',
      meta: {
        title: 'route.DateAnalysis',
        icon: 'mdi-google-analytics',
        roles: ['admin', 'user', 'manager'],
        affix: true,
      },
    },
    {
      path: 'comparison',
      component: () => import('@/views/components/Analysis/SantralComparison.vue'),
      name: 'Comparison',
      meta: {
        title: 'route.Comparison',
        icon: 'mdi-compare',
        roles: ['admin', 'user', 'manager'],
        affix: true,
        // roles: ['superuser']
      },
    },
    /*  {
       path: 'compa',
       component: () => import('@/views/components/Analysis/Comparison.vue'),
       name: 'compa',
       meta: {
         title: 'route.Comparison',
         icon: 'mdi-compare',
         roles: ['admin', 'user', 'manager'],
         affix: true
       },
     }, */
  /*   {
      path: 'analiz',
      component: () => import('@/views/components/Analysis/Index.vue'),
      name: 'Analiz',
      meta: {
        title: 'route.Analysis',
        icon: 'mdi-chart-bar',
        noCache: true,
        affix: true,
        roles: ['admin', 'user', 'manager'],
        // roles: ['superuser']
      },
    }, */

  ],
};

export default Analysis;
