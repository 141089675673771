import Layout from '@/views/layout/TheLayout.vue';

const notificationRouter = {
  path: '/notification',
  component: Layout,
  redirect: 'noredirect',
  name: 'Notification',
  meta: {
    title: 'route.notification',
    icon: 'mdi-bell',
    roles: ['admin', 'user', 'manager'],

  },
  children: [{
    path: 'error',
    component: () => import('@/views/components/Notification/Error.vue'),
    name: 'Error',
    meta: {
      title: 'Error',
      roles: ['admin', 'manager', 'user'],

    },
  },
  {
    path: 'warning',
    component: () => import('@/views/components/Notification/Warning.vue'),
    name: 'Warning',
    meta: {
      title: 'Warning',
      roles: ['admin', 'manager', 'user'],

    },
  },
  {
    path: 'device',
    component: () => import('@/views/components/Notification/ErrorTime.vue'),
    name: 'device',
    meta: {
      title: 'Cihazlar',
      roles: ['admin', 'manager', 'user'],

    },
  },
  ],
};

export default notificationRouter;
