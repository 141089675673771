import request from '@/api/utils';
import router from '@/router/index';
import helper from '@/api/helper';

const user = {
  state: {
    selectedUserPrefix: '',
    token: '',
    roles: [],
    mainPrefix: '',
    username: '',
    clientId: '',
    lastname: '',
    firstname: '',
    email: '',
    title: '',
    avatar: '',
    status: '',
    code: '',
    prefix: '',
    company_name: '',
    prefixList: [],
    introduction: '',
    setting: {
      articlePlatform: [],
    },
    loginStatus: '',
    location: {},
  },

  getters: {
    selectedUserPrefix: (state) => state.selectedUserPrefix,
    token: (state) => state.token,
    roles: (state) => state.roles,
    username: (state) => state.username,
    firstname: (state) => state.firstname,
    lastname: (state) => state.lastname,
    company_name: (state) => state.company_name,
    avatar: (state) => state.avatar,
    status: (state) => state.status,
    introduction: (state) => state.introduction,
    setting: (state) => state.setting,
    prefix: (state) => state.prefix,
    mainPrefix: (state) => state.mainPrefix,
    prefixList: (state) => state.prefixList,
    clientId: (state) => state.clientId,
    loginStatus: (state) => state.loginStatus,
    location: (state) => state.location,
  },

  mutations: {

    SET_SELECTED_USER_PREFIX: (state, prefix) => {
      state.selectedUserPrefix = prefix;
    },

    SET_USER_INFO: (state, payload) => {
      if (payload.logout) {
        state.token = '';
        state.roles = [];
        state.company_name = '';
        state.username = '';
        state.clientId = '';
        state.lastname = '';
        state.firstname = '';
        state.avatar = '';
        state.code = '';
        state.status = '';
        state.prefix = '';
        state.mainPrefix = '';
        state.prefixList = [];
        state.location = {};
      } else {
        state.token = payload.token || state.token;
        state.roles = payload.roles || state.roles;
        state.lastname = payload.lastname || state.lastname;
        state.email = payload.email || state.email;
        state.status = payload.status || state.status;
        state.username = payload.username || state.username;
        state.clientId = payload.clientId || state.clientId;
        state.firstname = payload.firstname || state.firstname;
        state.company_name = payload.company_name || state.company_name;
        state.prefix = payload.prefix || state.prefix;
        state.mainPrefix = payload.mainPrefix || state.mainPrefix;
        state.prefixList = payload.prefixList || state.prefixList;
        state.location = payload.location || state.location;
        // state.avatar = payload.avatar || state.avatar;
        // state.code = payload.status || state.status;
      }
    },
    SET_USER_LOGOUT: (state) => {
      state.token = '';
      state.roles = [];
      state.username = '';
      state.clientId = '';
      state.lastname = '';
      state.company_name = '';
      state.firstname = '';
      state.avatar = '';
      state.code = '';
      state.status = '';
      state.prefix = '';
      state.mainPrefix = '';
      state.prefixList = [];
      state.location = {};
    },

    SET_USER_LOGIN: (state, payload) => {
      state.token = payload.token || state.token;
      state.roles = payload.roles || state.roles;
      state.lastname = payload.lastname || state.lastname;
      state.clientId = payload.clientId || state.clientId;
      state.username = payload.username || state.username;
      state.firstname = payload.firstname || state.firstname;
      state.company_name = payload.company_name || state.company_name;
      // state.avatar = payload.avatar || state.avatar;
      state.status = payload.status || state.status;
      state.prefix = payload.prefix || state.prefix;
      state.email = payload.email || state.email;
      state.mainPrefix = payload.mainPrefix || state.mainPrefix;
      state.prefixList = payload.prefixList || state.prefixList;
      state.location = payload.location || state.location;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_LOGIN_STATUS: (state, status) => {
      state.loginStatus = status;
    },
  },

  actions: {

    SelectedUserPrefix: async ({
      commit,
    }, payload) => {
      commit('SET_SELECTED_USER_PREFIX', payload);
    },
    LoginByEmail: async ({
      commit,
    }, payload) => {
      try {
        const params = {
          username: payload.email.trim(),
          password: payload.password,
        };
        const rest = request.Api('/panel/auth/login', params, 'post');

        rest.then((res) => {
          if (res.status !== 200) {
            if (res.data.message) {
              commit('SET_LOGIN_STATUS', res.data.message);
            } else {
              commit('SET_LOGIN_STATUS', 'Giriş yapılamadı');
            }
          }

          if (res.data.success === 'true') {
            const storage = window.localStorage;
            storage.setItem('vuex_user', res.data.data);
            const token = helper.parseJwt(res.data.data);

            const obj = {
              token: res.data.data,
              roles: token.data.roles,
              mainPrefix: token.data.mainPrefix,
              prefixList: token.data.prefixList,
              prefix: token.data.prefix,
              clientId: token.data.clientId,
              username: token.data.username,
              lastname: token.data.lastname,
              firstname: token.data.firstname,
              email: token.data.email,
              status: token.data.status,
              createtime: token.data.createtime,
              company_name: token.data.company_name,
              location: token.data.location,
            };
            storage.setItem('selectedprefix', window.btoa(token.data.prefix));
            commit('SET_USER_LOGIN', obj);
            // router.push('/dashboard');
            window.location = '/dashboard';
            // window.location.reload();
          } else {
            router.push('/singin');
          }
        });
      } catch (err) {
        console.warn('[vuex.user] LoginByEmail', err);
      }
    },

    GetUserInfo: async ({
      commit,
      state,
    }) => {
      try {
        const response = {
          token: state.token,
          username: state.username,
          roles: state.roles,
          prefix: state.prefix,
          mainPrefix: state.mainPrefix,
          clientId: state.clientId,
          prefixList: state.prefixList,
          company_name: state.company_name,
          lastname: state.lastname,
          firstname: state.firstname,
          email: state.email,
          status: state.status,
          location: state.location,
        };

        // Since mockjs does not support custom status codes, it can only be hacked like this
        if (!response) {
          throw new Error('Verification failed, please login again.');
        }

        // Verify returned roles are a non-null array
        if (response.roles && response.roles.length === 0) {
          throw new Error('getInfo: roles must be a non-null array!');
        }

        commit('SET_USER_LOGIN', response);
        commit('SET_USER_DATA', state.userData);
        return response;
      } catch (err) {
        console.warn('[vuex.user] GetUserInfo', err);
        return {};
      }
    },

    LogOut: async ({
      commit,
    }) => {
      try {
        console.log('[vuex.user] LogOut');
        await commit('SET_USER_LOGOUT', {
          logout: true,
        });
      } catch (err) {
        console.warn('[vuex.user] LogOut', err);
      }
    },

    // Dynamically modify permissions
    ChangeRoles: async ({
      commit,
      dispatch,
      state,
    }, role) => {
      try {
        const response = {
          token: state.token,
          username: state.username,
          roles: state.roles,
          prefix: state.prefix,
          mainPrefix: state.mainPrefix,
          clientId: state.clientId,
          prefixList: state.prefixList,
          company_name: state.company_name,
          lastname: state.lastname,
          firstname: state.firstname,
          email: state.email,
          status: state.status,
          location: state.location,
        };

        await commit('SET_TOKEN', role);
        await commit('SET_USER_LOGIN', response);
        await commit('SET_USER_DATA', state.userData);
        // Redraw the side menu after dynamically modifying the permissions
        await dispatch('GenerateRoutes', response);
      } catch (err) {
        console.warn('[vuex.user] ChangeRoles', err);
      }
    },

  },
};

export default user;
