<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn
        icon
        text
        v-on="on"
      >
        <v-icon>
          mdi-translate
        </v-icon>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list-item
        v-for="(item,index) in locales"
        :key="index"
        ripple="ripple"
        :target="item.target"
        rel="noopener"
        @click="setLocale(item.locale)"
      >
        <v-list-item-avatar
          class="p-0 m-0"
        >
          <v-img
            :src="getImage(item.source)"
            height="30"
            width="20"
          />
        </v-list-item-avatar>
        <v-list-item-content class="pl-1">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AppLocalization',
  data: () => ({
    locales: [
      {
        iconClass: 'blue white--text',
        title: 'Turkish',
        locale: 'tr',
        abbr: 'TUR',
        source: 'turkish.png',
      },
      {
        iconClass: 'blue white--text',
        title: 'English',
        locale: 'en',
        abbr: 'ENG',
        source: 'uk.png',
      },
    ],
  }),
  methods: {
    async setLocale(locale) {
      await this.$store.dispatch('SetLocale', { locale });
    },
    getImage(param) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/views/widget/images/${param}`);
    },
  },
};
</script>
