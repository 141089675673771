// eslint-disable-next-line import/no-cycle
import request from '../../api/utils';

const common = {
  state: {
    platformList: [],
    clientYearList: [],
    dimensionMeasureList: [],
    month: [],
    measureList: [],
    statsmeasureList: [],
    sensormeasureList: [],
    generalStatsMeasureList: [],
    santralsensor: [],
    santralsensormeasure: [],
    generalSantralInfo: [],
    santralInfo: [],
    inverterList: [],
    inverterInfo: [],
    inverterName: [],
    stringboxInfo: [],
    stringboxList: [],
    stringboxName: [],
    sensorInfo: [],

    inverterDcPower: [],
    dash_stats_measures_list: [],
  },
  getters: {
    platformList: (state) => state.platformList,
    clientYearList: (state) => state.clientYearList,
    dimensionMeasureList: (state) => state.dimensionMeasureList,
    month: (state) => state.month,
    measureList: (state) => state.measureList,
    statsmeasureList: (state) => state.statsmeasureList,
    sensormeasureList: (state) => state.sensormeasureList,
    santralsensor: (state) => state.santralsensor,
    generalStatsMeasureList: (state) => state.generalStatsMeasureList,
    santralsensormeasure: (state) => state.santralsensormeasure,
    generalSantralInfo: (state) => state.generalSantralInfo,
    santralInfo: (state) => state.santralInfo,

    inverterList: (state) => state.inverterList,
    inverterInfo: (state) => state.inverterInfo,
    inverterName: (state) => state.inverterName,
    stringboxList: (state) => state.stringboxList,
    stringboxInfo: (state) => state.stringboxInfo,
    stringboxName: (state) => state.stringboxName,

    sensorInfo: (state) => state.sensorInfo,
    inverterDcPower: (state) => state.inverterDcPower,
    dash_stats_measures_list: (state) => state.dash_stats_measures_list,

  },
  mutations: {
    SET_PLATFORM_LIST: (state, payload) => {
      state.platformList = payload;
    },
    SET_CLIENT_YEAR_LIST: (state, payload) => {
      state.clientYearList = payload;
    },
    SET_DIMENSION_MEASURE_LIST: (state, payload) => {
      state.dimensionMeasureList = payload;
    },
    SET_MONTH: (state, payload) => {
      state.month = payload;
    },
    SET_MEASURE_LIST: (state, payload) => {
      state.measureList = payload;
    },
    SET_STATS_MEASURE_LIST: (state, payload) => {
      state.statsmeasureList = payload;
    },
    SET_SENSOR_MEASURE_LIST: (state, payload) => {
      state.sensormeasureList = payload;
    },
    SET_SANTRAL_SENSOR: (state, payload) => {
      state.santralsensor = payload;
    },
    SET_GENERAL_STATS_MEASURES: (state, payload) => {
      state.generalStatsMeasureList = payload;
    },
    SET_SANTRAL_SENSOR_MEASURE: (state, payload) => {
      state.santralsensormeasure = payload;
    },

    SET_GENERAL_SANTRAL_INFO: (state, payload) => {
      state.generalSantralInfo = payload;
    },
    SET_SANTRAL_INFO: (state, payload) => {
      state.santralInfo = payload;
    },

    SET_INVERTER_LIST: (state, payload) => {
      state.inverterList = payload;
    },
    SET_INVERTER_INFO: (state, payload) => {
      state.inverterInfo = payload;
    },
    SET_INVERTERNAME: (state, payload) => {
      state.inverterName = payload;
    },

    SET_STRINGBOX_LIST: (state, payload) => {
      state.stringboxList = payload;
    },
    SET_STRINGBOX_INFO: (state, payload) => {
      state.stringboxInfo = payload;
    },
    SET_STRINGBOXNAME: (state, payload) => {
      state.stringboxName = payload;
    },

    SET_SENSOR_INFO: (state, payload) => {
      state.sensorInfo = payload;
    },
    SET_STATS_DASH_MEASURE: (state, payload) => {
      state.dash_stats_measures_list = payload;
    },

  },
  actions: {

    getMeasuresList: async ({
      commit,
    }) => {
      try {
        const req = request.Api('/panel/common/measureslist', {}, 'post');

        req.then((res) => {
          const result = res.data.data;

          const temps = [];
          const _temps = [];

          Object.keys(result.stats_Measures_list).forEach(
            (k) => {
              const tmp = result.stats_Measures_list[k];
              if (_temps.indexOf(tmp.value) < 0) {
                temps.push(tmp);
                _temps.push(tmp.value);
              }
            },
          );
          const stats_dash_measures_list = [];
          Object.keys(result.stats_Measures_list).forEach(
            (k) => {
              const tmp = result.stats_Measures_list[k];
              if (stats_dash_measures_list.indexOf(tmp.value) < 0) {
                stats_dash_measures_list.push(tmp);
              }
            },
          );

          const stemps = [];
          const sensor = {};
          const santral_info = {};
          const inverterInfo = {};
          const inverterList = {};
          const inverterName = {};
          const stringboxInfo = {};
          const stringboxList = {};
          const stringboxListName = {};
          const sensorList = {};
          const sensorInfo = {};
       
          Object.keys(result.santral).forEach((i) => {
            const {
              santral
            } = result.santral[i];
           

            inverterList[santral] = Object.keys(result.santral[i].INVERTER);
            inverterInfo[santral] = result.santral[i].INVERTER;

            const temp_inv = [];
            Object.keys(result.santral[i].INVERTER).forEach((k) => {
              temp_inv.push({
                text: result.santral[i].INVERTER[k].INVERTERNAME,
                value: result.santral[i].INVERTER[k].INVERTER,
              });
            });
            inverterName[santral] = temp_inv;

            /// ////////////////stringbox

            stringboxList[santral] = Object.keys(result.santral[i].STRINGBOX);
            stringboxInfo[santral] = result.santral[i].STRINGBOX;

            const temp_str = [];
            Object.keys(result.santral[i].STRINGBOX).forEach((k) => {
              temp_str.push({
                text: result.santral[i].STRINGBOX[k].STRINGBOXNAME,
                value: result.santral[i].STRINGBOX[k].STRINGBOX,
              });
            });
            stringboxListName[santral] = temp_str;

            /// ////////////////stringbox

            if (result.santral[i].SENSOR !== false) {
              // sensorList[santral] = Object.keys(result.santral[i].SENSOR);
              sensorInfo[santral] = result.santral[i].SENSOR;
            }

            stringboxList[santral] = Object.keys(result.santral[i].STRINGBOX);
            stringboxInfo[santral] = result.santral[i].STRINGBOX;

            santral_info[santral] = result.santral[i];
            sensor[santral] = [];
            if (!isNaN(result.santral[i].SENSOR)) {
              Object.keys(result.santral[i].SENSOR).forEach(
                (k) => {
                  sensor[santral].push(k);
                },
              );
            }
          });

 
          commit('SET_INVERTER_LIST', inverterList);
          commit('SET_INVERTER_INFO', inverterInfo);
          commit('SET_INVERTERNAME', inverterName);
          commit('SET_STRINGBOX_LIST', stringboxList);
          commit('SET_STRINGBOX_INFO', stringboxInfo);
          commit('SET_STRINGBOXNAME', stringboxListName);
          commit('SET_SENSOR_INFO', sensorInfo);
          commit('SET_MEASURE_LIST', result);
          commit('SET_STATS_MEASURE_LIST', temps);
          commit('SET_SENSOR_MEASURE_LIST', stemps);
          commit('SET_SANTRAL_SENSOR', sensor);
          commit('SET_SANTRAL_SENSOR_MEASURE', result.sensor_Measures_list);
          commit('SET_GENERAL_STATS_MEASURES', result.STATS);
          commit('SET_GENERAL_SANTRAL_INFO', result.santral);
          commit('SET_SANTRAL_INFO', santral_info);
          commit('SET_STATS_DASH_MEASURE', stats_dash_measures_list);
        });
      } catch (err) {
        console.log('SET_MEASURE_LIST', err);
      }
    },

    getClientYearList: async ({
      commit,
    }) => {
      try {
        const rest = request.Api('/panel/common/yearList', {}, 'post');
        rest.then((res) => {
          if (res.status !== 200) return;
          if (res.data.success === 'true') {
            commit('SET_CLIENT_YEAR_LIST', res.data.data);
          }
        });
      } catch (err) {
        console.error('getClientYearList', err);
      }
    },

    getMonthly: async ({
      commit,
    }) => {
      try {
        const MonthList = [];
        const month = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz',
          'Agustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık',
        ];

        for (let i = 0; i < month.length; i++) {
          MonthList.push({
            text: month[i],
            value: i + 1,
          });
        }

        commit('SET_MONTH', MonthList);
      } catch (err) {

      }
    },
  },
};

export default common;
