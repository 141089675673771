// eslint-disable-next-line import/no-cycle,no-unused-vars
import {
  getSantralList,
} from '@/api/Request/santral';
import request from '@/api/utils';

const santalData = {
  state: {
    santralRawData: [],
    santralDataList: [],

    generalMeasures: [],
    santralLiveEnerg: {
      dasf: 'dsf',
    },
    liveDataLList: [],

    santralDailyMinuteEnerg: {},
    combinerboxList: {},
    stringPanelQuantity: {},
    combinerboxDCCurrent: {},
    combinerboxDCCurrent_List: {},
    stringDcCurrent: {},
    generalStatsData: [],
    selectedSantralModel: {},
    santralList: {},
    santralgeneralupdate: ',',
    mainUserList: {},
    stepTime: 10,
  },
  getters: {
    selectedSantralModel: (state) => state.selectedSantralModel,

    stringDcCurrent: (state) => state.stringDcCurrent,
    combinerboxDCCurrent: (state) => state.combinerboxDCCurrent,
    combinerboxDCCurrent_List: (state) => state.combinerboxDCCurrent_List,
    stringPanelQuantity: (state) => state.stringPanelQuantity,
    combinerboxList: (state) => state.combinerboxList,
    generalMeasures: (state) => state.generalMeasures,
    santralRawData: (state) => state.santralRawData,
    santralDataList: (state) => state.santralDataList,
    santralLiveEnerg: (state) => state.santralLiveEnerg,

    santralDailyMinuteEnerg: (state) => state.santralDailyMinuteEnerg,
    generalStatsData: (state) => state.generalStatsData,
    santralList: (state) => state.santralList,
    santralgeneralupdate: (state) => state.santralgeneralupdate,
    mainUserList: (state) => state.mainUserList,
    stepTime: (state) => state.stepTime,
  },
  mutations: {
    SET_SELECTED_SANTRAL_DATA: (state, payload) => {
      state.selectedSantralModel = payload;
    },
    SET_SANTRAL_DATA_LIST: (state, payload) => {
      state.santralDataList = payload;
    },
    SET_SANTRAL_RAW_DATA_LIST: (state, payload) => {
      state.santralRawData = payload;
    },

    SET_GENERAL_MEASURES: (state, payload) => {
      state.generalMeasures = payload;
    },
    SET_SANTRAL_LIVE_ENERG: (state, payload) => {
      state.santralLiveEnerg = payload;
    },
    SET_COMBINERBOX_LIST: (state, payload) => {
      state.combinerboxList = payload;
    },
    SET_STRING_PANEL_QUANTITY: (state, payload) => {
      state.stringPanelQuantity = payload;
    },
    SET_COMBINERBOX_DCCURRENT: (state, payload) => {
      state.combinerboxDCCurrent = payload;
    },
    SET_COMBINERBOX_DCCURRENT_LIST: (state, payload) => {
      state.combinerboxDCCurrent_List = payload;
    },

    SET_STRING_CURRENT: (state, payload) => {
      state.stringDcCurrent = payload;
    },

    SET_SANTRAL_DAILY_MINUTE_ENERG: (state, payload) => {
      state.santralDailyMinuteEnerg = payload;
    },
    SET_GENERAL_STATS_DATA: (state, payload) => {
      state.generalStatsData = payload;
    },

    SET_SANTRAL_LIST: (state, payload) => {
      state.santralList = payload;
    },
    SET_GENERAL_UPDATE_TIME: (state, payload) => {
      state.santralgeneralupdate = payload;
    },
    SET_MAIN_PREFIX_LIST: (state, payload) => {
      state.mainUserList = payload;
    },

    SET_STEP_TIME: (state, payload) => {
      state.stepTime = payload;
    },
  },
  actions: {

    setGeneralUpdateTime: async ({
      commit,
    }, payload) => {
      commit('SET_GENERAL_UPDATE_TIME', payload);
    },

    setSelectedSantral: async ({
      commit,
    }, payload) => {
      commit('SET_SELECTED_SANTRAL_DATA', payload);
    },

    setGeneralStatsData: async ({
      commit,
    }, payload) => {
      try {
        const req = request.Api(payload.url, payload.params, 'post');
        req.then((res) => {
          if (res.status !== 200) {
            commit('SET_GENERAL_STATS_DATA', {});
            return;
          }
          if (res.data.success !== 'true') {
            commit('SET_GENERAL_STATS_DATA', {});
            return;
          }
          commit('SET_GENERAL_STATS_DATA', res.data.data);
        });
      } catch (err) {
        console.log('setGeneralStatsData', err);
      }
    },

    getSantralLiveEnerg: async ({
      commit,
    }, payload) => {
      try {
        const rest = request.Api('/panel/santral/hourpower', payload, 'post');
        const tableData = {};
        rest.then((res) => {
          if (res.status !== 200) return;
          if (res.data.success === 'true') {
            const header = [];
            Object.keys(res.data.data[0]).forEach((m) => {
              header.push(m);
            });
            tableData.headers = header;
            tableData.items = res.data.data;
            commit('SET_SANTRAL_LIVE_ENERG', tableData);
          }
        });
      } catch (err) {
        console.log('getSantralLiveEnerg', err);
      }
    },

    getSantralDailyData: async ({
      commit,
    }, payload) => {
      try {
        const rest = request.post(payload.funcName, payload.query);
        rest.then((res) => {
          const result = JSON.parse(JSON.stringify(res.data.data));
          if (res.status !== 200) return;
          if (res.data.success === true) {
            const obj = {};
            obj.result = result;
            obj.reportCode = payload.reportCode;
            commit('SET_DASH_LIST', obj);
          }
        });
      } catch (err) {
        console.warn('[vuex.user] GetCubeList', err);
      }
    },

    setGeneralMeasures: async ({
      commit,
    }) => {
      const measureList = [{
          text: 'Daily Energy',
          value: 'DailyEnergy',
        },
        {
          text: 'Performance Ratio',
          value: 'PerformanceRatio',
        },
        {
          text: 'Theoretical Power',
          value: 'TheoreticalPower',
        },
      ];

      commit('SET_GENERAL_MEASURES', measureList);
    },

    getSantralData: async ({
      commit,
    }, payload) => {
      try {
        getSantralList(payload).then((result) => {
          if (result.status !== 200) return;
          if (result.data.success !== 'true') return;

          if (result.data.data.length === 0) {
            return;
          }

          const santralList = {};
          const santralDataList = [];
          let steptime = 5;
          result.data.data.forEach((item) => {
            santralDataList.push(item.santral);
            santralList[item.santral] = item;
            if (steptime < item.dataLoggerTimeInterval) {
              steptime = item.dataLoggerTimeInterval;
            }
          });

          commit('SET_SELECTED_SANTRAL_DATA', result.data.data[0]);
          commit('SET_SANTRAL_DATA_LIST', santralDataList);
          commit('SET_SANTRAL_LIST', santralList);
          commit('SET_SANTRAL_RAW_DATA_LIST', result.data.data);


          if (steptime !== undefined || steptime !== null) {
            commit('SET_STEP_TIME', steptime);
          }
        });
      } catch (err) {
        console.log('getSantralDataError', err);
      }
    },

    getMainUser: async ({
      commit,
    }, payload) => {
      try {
        const rest = request.Api('/panel/santral/mainuser', payload, 'post');
        rest.then((res) => {
          if (res.status !== 200) return;
          if (res.data.success === 'true') {
            commit('SET_MAIN_PREFIX_LIST', res);
          }
        });
      } catch (err) {
        console.log('getSantralLiveEnerg', err);
      }
    },
  },
};

export default santalData;
