// eslint-disable-next-line import/no-cycle
import request from '../../api/utils';

const common = {
  state: {
    sensorList: [],
  },
  getters: {
    sensorList: (state) => state.sensorList,

  },
  mutations: {
    SET_SENSOR_LIST: (state, payload) => {
      state.sensorList = payload;
    },

  },
  actions: {

    getSensorList: async ({
      commit,
    }) => {
      try {
        const req = request.Api('/panel/sensorbox/sensorinfo', {}, 'post');

        req.then((res) => {
          if (res.status !== 200) return;
          if (res.data.success !== 'true') return;

          commit('SET_SENSOR_LIST', res.data.data);
        });
      } catch (err) {
        console.log('SET_SENSOR_LIST', err);
      }
    },

  },
};

export default common;
